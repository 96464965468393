import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useAuthContext } from '../components/authContext';
import FooterButton from '../components/footerButton';
import { useFormContext } from '../components/formContext';
import Layout from '../components/layout';
import LoadingWrapper from '../components/loadingWrapper';
import SelectButtonGroup from '../components/selectButtonGroup';
import TextInput from '../components/textInput';
import { FormActions, FormState, useNewForm } from '../forms/form';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useConfig from '../hooks/useConfig';
import { Suggestions, useSuggestions } from '../hooks/useSuggestions';
import {
  ConditionExplanationText,
  goodConditionIsSelected,
} from '../services/conditions';
import { IndividualClient } from '../services/interactions';
import {
  FORM_TYPE_TITLE,
  FormConfiguration,
  FormType,
  YES_NO,
} from '../services/lists';
import { BaseProps } from '../typings/types';

interface PureEditClientProps {
  form: FormState;
  dispatch: React.Dispatch<FormActions>;
  config?: FormConfiguration;
  suggestions: Suggestions;
}

const useStyles = makeStyles({
  header: {
    marginBottom: '1.25rem',
  },
});

export const PureEditClient: React.FC<PureEditClientProps> = ({
  form,
  dispatch,
  config,
  suggestions,
}) => {
  const classes = useStyles();
  const clients = form.clientsToEdit.map(
    clientIndex => form.individualClients[clientIndex] as IndividualClient
  );
  return (
    <Layout
      navigateBackToPage="/edit-clients"
      title={FORM_TYPE_TITLE[form.formType]}
    >
      <Grid alignContent="flex-start" justify="space-between" container>
        <LoadingWrapper
          loading={!config}
          submitting={false}
          hasConfig={true}
          submitted={false}
        >
          <Typography className={classes.header} variant="h6" component="h2">
            {clients.length === 1
              ? `Client ${clients[0].index + 1} ${clients[0].ageGroup} ${
                  clients[0].gender
                }`
              : `Editing ${clients.length} clients.`}
          </Typography>
          {config && (
            <form
              data-testid="loaded"
              autoComplete="off"
              onSubmit={(e): void => {
                e.preventDefault();
                dispatch({
                  type: 'SUBMIT_CLIENT_EDITS',
                });
              }}
            >
              {![FormType.Community, FormType.Yes].includes(form.formType) && (
                <SelectButtonGroup
                  id="reason"
                  multi
                  label="Reason"
                  value={clients[0].reasons}
                  options={config.reasons}
                  suggestOther={suggestions.suggestReason}
                  onChange={(value): void => {
                    dispatch({
                      type: 'CHANGE_CLIENT_REASON',
                      reasons: value as string[],
                    });
                  }}
                />
              )}

              {form.formType !== FormType.Community && (
                <>
                  <SelectButtonGroup
                    id="condition"
                    options={config.conditions}
                    label="Condition"
                    value={clients[0].condition}
                    suggestOther={suggestions.suggestCondition}
                    onChange={(value): void => {
                      dispatch({
                        type: 'CHANGE_CLIENT_CONDITION',
                        condition: value as string[],
                      });
                    }}
                    disableOption={goodConditionIsSelected}
                    multi
                  />
                  <ConditionExplanationText conditions={clients[0].condition} />
                </>
              )}

              <SelectButtonGroup
                id="community"
                options={config.communities}
                label="Community of origin (optional)"
                value={clients[0].community}
                suggestOther={suggestions.suggestCommunity}
                onChange={(value): void => {
                  dispatch({
                    type: 'CHANGE_CLIENT_COMMUNITY',
                    community: value as string,
                  });
                }}
              />

              {form.formType !== FormType.Community && (
                <SelectButtonGroup
                  id="repeat-client"
                  options={YES_NO}
                  label="Repeat client?"
                  value={clients[0].repeatCustomer}
                  onChange={(value): void => {
                    dispatch({
                      type: 'CHANGE_CLIENT_REPEAT_CUSTOMER',
                      repeatCustomer: value as string,
                    });
                  }}
                />
              )}

              {form.formType !== FormType.Community && (
                <SelectButtonGroup
                  id="follow-up-required"
                  options={YES_NO}
                  label="Follow up required?"
                  value={clients[0].followUp}
                  onChange={(value): void => {
                    dispatch({
                      type: 'CHANGE_CLIENT_FOLLOW_UP',
                      followUp: value as string,
                    });
                  }}
                />
              )}

              {clients[0].followUp === 'Yes' && (
                <SelectButtonGroup
                  id="follow-up-reason"
                  label="Follow up action"
                  options={config.followUpActions}
                  value={clients[0].followUpActions}
                  suggestOther={suggestions.suggestFollowUpAction}
                  multi
                  onChange={(followUpActions): void =>
                    dispatch({
                      type: 'CHANGE_CLIENT_FOLLOW_ACTION',
                      followUpActions: followUpActions as string[],
                    })
                  }
                />
              )}

              {clients[0].followUp === 'Yes' && (
                <SelectButtonGroup
                  id="follow-up-time"
                  label="Follow up time"
                  suggestOther={suggestions.suggestFollowUpTime}
                  options={config.followUpTimes}
                  value={clients[0].followUpTime}
                  onChange={(followUpTime): void =>
                    dispatch({
                      type: 'CHANGE_CLIENT_FOLLOW_UP_TIME',
                      followUpTime: followUpTime as string,
                    })
                  }
                />
              )}

              {form.formType !== FormType.Community && (
                <TextInput
                  label="Case number (optional)"
                  id="case-number"
                  name="caseNumber"
                  value={clients[0].caseNumber ? clients[0].caseNumber : ''}
                  placeholder="Add number"
                  onChange={(e): void => {
                    dispatch({
                      type: 'CHANGE_CLIENT_CASE_NUMBER',
                      caseNumber: e.target.value,
                    });
                  }}
                />
              )}

              {form.formType === FormType.Transport && (
                <TextInput
                  label="Name (optional)"
                  id="name"
                  name="name"
                  value={clients[0].name}
                  placeholder="First name"
                  onChange={(e): void => {
                    dispatch({
                      type: 'CHANGE_CLIENT_NAME',
                      name: e.target.value,
                    });
                  }}
                />
              )}

              <FooterButton type="submit" disabled={form.submittingClientEdits}>
                UPDATE DETAILS
              </FooterButton>
            </form>
          )}
        </LoadingWrapper>
      </Grid>
    </Layout>
  );
};

export const EditClient: React.FC<BaseProps> = ({ location }) => {
  const { getUser } = useAuthContext();
  const pathname = location.pathname;
  useAuthRouteProtection(pathname);
  const { form: existingForm } = useFormContext();
  if (!existingForm?.formType) {
    throw new Error('Application error: No form context when adding clients.');
  }
  const { config } = useConfig();
  const { dispatch, ...form } = useNewForm({
    getUser,
    formType: existingForm?.formType,
    config: config?.items[existingForm?.formType],
  });
  const suggestions = useSuggestions(getUser);
  return (
    <PureEditClient
      form={form.initialised ? form : existingForm}
      dispatch={dispatch}
      config={config?.items[existingForm?.formType]}
      suggestions={suggestions}
    />
  );
};

export default EditClient;
